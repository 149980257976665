@font-face {
  font-family: "Helvetica Neue";
  src: local("HelveticaNeue Bold"), local("HelveticaNeue-Bold");
  src: url("helveticaneue-bold.woff2") format("woff2"),url("helveticaneue-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("HelveticaNeue Medium"), local("HelveticaNeue-Medium");
  src: url("helveticaneue-medium.woff2") format("woff2"),url("helveticaneue-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("HelveticaNeue Regular"), local("HelveticaNeue-Regular");
  src: url("helveticaneue.woff2") format("woff2"),url("helveticaneue.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("HelveticaNeue Light"), local("HelveticaNeue-Light");
  src: url("helveticaneue-light.woff2") format("woff2"),url("helveticaneue-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("HelveticaNeue Thin"), local("HelveticaNeue-Thin");
  src: url("helveticaneue-thin.woff2") format("woff2"),url("helveticaneue-thin.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

